import { styled } from '@cp/ds/theme/styled';

export type LogoColorScheme = 'light' | 'dark';
export interface LogoBaseProps {
  colorScheme?: LogoColorScheme;
  background?: boolean;
}

const SCHEME_TO_COLOR: Record<LogoColorScheme, { ink: LogoColorScheme; bg: LogoColorScheme }> = {
  'light': { ink: 'dark', bg: 'light' },
  'dark': { ink: 'light', bg: 'dark' },
};

export const Root = styled('div')<LogoBaseProps>(({ colorScheme = 'light', background }) => ({
  '--color--light': '#fff',
  '--color--dark': '#435058',
  '--dimension--offset': '3px',
  display: 'inline-flex',
  textDecoration: 'none',
  borderRadius: 'var(--dimension--radius)',
  color: 'var(--color--ink, #435058)',
  background: 'var(--color--paper)',
  paddingInlineStart: 'var(--dimension--offset)',
  paddingInlineEnd: 'var(--dimension--offset)',
  boxSizing: 'border-box',
  '--color--ink': `var(--color--${SCHEME_TO_COLOR[colorScheme].ink})`,
  ...(background && { '--color--paper': `var(--color--${SCHEME_TO_COLOR[colorScheme].bg})` }),
}));
