export const YOUTUBE_CHANNEL = 'https://www.youtube.com/@BabyTime_2019';

export const SOCIAL_MEDIA: {
  YOUTUBE_CHANNEL?: string;
  VK_GROUP?: string;
  OK_GROUP?: string;
} = {
  YOUTUBE_CHANNEL,
};
export type SocialMediaConfig = Partial<typeof SOCIAL_MEDIA>;
