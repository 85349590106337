import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TokenPayload } from '@common/model/auth';
import jwtDecode from 'jwt-decode';

export interface AuthenticationState {
  token: string | null;
  tokenPayload: TokenPayload | null;
}

const initialState: AuthenticationState = {
  token: null,
  tokenPayload: null,
};

export const authenticationReducerPath = 'authentication';
export type AuthenticationSliceShape = { [authenticationReducerPath]: AuthenticationState };

export const authenticationSlice = createSlice({
  name: '@@app/authentication',
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<AuthenticationState['token']>) {
      state.token = action.payload;
      state.tokenPayload = action.payload ? jwtDecode<TokenPayload>(action.payload) : null;
    },
    clearToken() {
      return initialState;
    },
  },
});
