import { ISemanticEvent, SemanticContext } from './semanticEvents';
import { Integration } from './integration';
import { EventBuffer } from './eventBuffer';

export class Spider {
  readonly #buffer: EventBuffer<ISemanticEvent>;
  #ctx: SemanticContext;
  #integrations: Integration[] = [];
  constructor(bufferSize: number, ctx: SemanticContext, integrations: Integration[] = []) {
    this.#buffer = new EventBuffer<ISemanticEvent>(bufferSize);
    this.#ctx = ctx;
    this.#integrations = integrations;
  }

  public updateContext(ctx: SemanticContext) {
    this.#ctx = ctx;
  }

  public addIntegration(integration: Integration): void {
    for (const event of this.#buffer) {
      if (!event) {
        continue;
      }
      integration.trackEvent(event, this.#ctx);
    }
    this.#integrations.push(integration);
  }

  public trackEvent(event: ISemanticEvent): void {
    this.#integrations.forEach((integration) => integration.trackEvent(event, this.#ctx));
    this.#buffer.push(event);
  }

  static createContext(ctx: Partial<SemanticContext>): SemanticContext {
    return Object.assign({ client: { id: null } }, ctx);
  }
}
