import { detectIsBrowser } from '@cp/utils/browser';
import { ConfigTypeOverride } from '@cp/shared/config';
import { createConfigManager } from '#app/config';
import { EventBus } from './eventBus';
import { AppService } from './appService';
import { SpiderService } from './tracking';

const bootsrtapFlag = Symbol('bootsrtapFlag');

export const bootstrap = (config: Partial<ConfigTypeOverride>) => {
  const IS_BROWSER = detectIsBrowser();
  const global = (IS_BROWSER ? window : {}) as Record<string, unknown>;
  // @ts-ignore
  if (global[bootsrtapFlag]) {
    return;
  }
  // @ts-ignore
  global[bootsrtapFlag] = true;
  const configManager = createConfigManager(config);
  const eventBus = new EventBus();
  const appService = new AppService(eventBus);
  const spiderService = new SpiderService(configManager);
  eventBus.subscribe((e) => console.debug('[EventBus]', e));

  global['configManager'] = configManager;
  global['appService'] = appService;
  global['spiderService'] = spiderService;
  global['eventBus'] = eventBus;
  console.info('[bootstrap]', 'finished');
};
