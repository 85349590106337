import mediaQuery from 'css-mediaquery';
import { BREAKPOINTS_CONFIG } from '@cp/ds';
import { DeviceType } from '@common/model/reactApp/deviceType';

export const deviceTypeToScreenWidth: Record<DeviceType, number> = {
  'mobile': BREAKPOINTS_CONFIG.cm,
  'tablet': BREAKPOINTS_CONFIG.md,
  'desktop': BREAKPOINTS_CONFIG.xl,
};

export const getSsrMatchMedia = (deviceType: DeviceType) => {
  return (query: string) => ({
    matches: mediaQuery.match(query, {
      // The estimated CSS width of the browser.
      width: `${deviceTypeToScreenWidth[deviceType]}px`,
    }),
  });
};
