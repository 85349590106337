export const SUPPORT: {
  SUPPORT_VIBER?: string;
  SUPPORT_VK?: string;
  SUPPORT_WHATSAPP?: string;
  SUPPORT_TELEGRAM?: string;
} = {
  // SUPPORT_VIBER: 'viber://chat?number=%2B79223015878',
  // SUPPORT_VK: 'https://vk.com/youhelp_ru',
  // SUPPORT_WHATSAPP: 'https://api.whatsapp.com/send?phone=74951285457',
  SUPPORT_TELEGRAM: 'https://t.me/womanly_vi',
};
export type SupportConfig = Partial<typeof SUPPORT>;
