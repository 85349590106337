import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CommonModalProps, ModalDictionary } from '../../../../app/shared/components/modalProvider/model';

export interface ModalQueueItem<N extends keyof ModalDictionary> {
  name: N;
  props?: CommonModalProps & ModalDictionary[N];
}

export interface ModalQueueState {
  items: ModalQueueItem<keyof ModalDictionary>[];
}

const initialState: ModalQueueState = {
  items: [],
};

export const modalQueueSlice = createSlice({
  name: '@@app/modal/queue',
  initialState,
  reducers: {
    push(state, action: PayloadAction<ModalQueueItem<keyof ModalDictionary>>) {
      // ToDo: fix for multiple modals opening with same name and props
      let sequenceOfModalWithSameNameEnded = false;

      const items = state.items
        .slice()
        .reverse()
        .filter((item) => {
          if (sequenceOfModalWithSameNameEnded) {
            return true;
          }

          sequenceOfModalWithSameNameEnded = item.name !== action.payload.name;

          if (sequenceOfModalWithSameNameEnded) {
            return true;
          }

          return JSON.stringify(item.props) !== JSON.stringify(action.payload.props);
        })
        .reverse()
        .concat([action.payload]);

      return {
        ...state,
        items,
      };
    },
    shift(state): void {
      state.items.shift();
    },
  },
});
