import 'reflect-metadata';
import Head from 'next/head';
import React, { useEffect, useMemo } from 'react';
import type { AppProps } from 'next/app';
import { AppCacheProvider } from '@mui/material-nextjs/v14-pagesRouter';
import { ThemeProvider } from '@cp/ui/theme';
import { TokenProvider } from '@cp/ui/themes/bt';
import CssBaseline from '@mui/material/CssBaseline';
import { createThemeBT } from '@cp/ds/theme';
import { Provider } from 'react-redux';
import { getSsrMatchMedia } from 'src/ssrMatchMedia';
import { HelmetComponent, HelmetContextProvider } from 'src/shared/helmet';
import { SvgIconCacheProvider } from '@cp/ds/src/components/svgIcon/components/cache/provider';
import { SvgIconCacheLibrary } from '@cp/ds/components/svgIcon/components/cache/library';
import { configureClientSideStore } from '#app/redux';
import { TrademarkProvider } from '#app/trademark';
import { PageProps } from '#app/page';
import { bootstrap } from '#app/bootstrap';
import { AppService } from '#app/appService';

export default (appProps: AppProps<PageProps>) => {
  const { Component, pageProps, router } = appProps;

  const store = useMemo(
    () =>
      configureClientSideStore({
        preloadedState: pageProps.state,
      }),
    [],
  );
  const theme = useMemo(() => {
    const ssrMatchMedia = getSsrMatchMedia(pageProps.deviceType ?? 'desktop');
    return createThemeBT('light', { ssrMatchMedia });
  }, []);

  useEffect(() => {
    bootstrap(appProps.pageProps.config);
  }, []);

  useEffect(() => {
    // @ts-ignore
    (window['appService'] as AppService).sendPageView({
      url: router.pathname,
      title: document.title,
      path: router.asPath,
      type: '',
    });
  }, []);

  useEffect(() => {
    const onRouteChange = (url: string, params: { shallow: boolean }) => {
      if (params.shallow) {
        return;
      }
      // @ts-ignore
      (window['appService'] as AppService).sendPageView({
        url,
        title: document.title,
        path: router.asPath,
        type: '',
      });
    };
    router.events.on('routeChangeComplete', onRouteChange);
    return () => router.events.off('routeChangeComplete', onRouteChange);
  }, [router]);

  return (
    <SvgIconCacheProvider>
      <Provider store={store}>
        <AppCacheProvider {...pageProps}>
          <ThemeProvider theme={theme}>
            <TokenProvider>
              <CssBaseline />
              <HelmetContextProvider value={Head as HelmetComponent}>
                <TrademarkProvider>
                  <Component {...pageProps} />
                </TrademarkProvider>
              </HelmetContextProvider>
            </TokenProvider>
          </ThemeProvider>
        </AppCacheProvider>
      </Provider>
      <SvgIconCacheLibrary />
    </SvgIconCacheProvider>
  );
};
