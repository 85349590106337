import { parseBoolean } from './parser/boolean';
import { parseNumber } from './parser/number';
import { parseString } from './parser/string';
export * from './manager.interface';
export * from './ctx';
export * from './errors';

const setHostname = (urlString: string, hostname: string) => {
  const url = new URL(urlString);
  url.hostname = hostname;
  return url.origin;
};

const createConfig = () => {
  const baseConfig = {
    CORE_SERVER_ORIGIN: parseString(process.env.CORE_SERVER_ORIGIN),
    CORE_SERVER_WEBSOCKET_ORIGIN: parseString(process.env.CORE_SERVER_WEBSOCKET_ORIGIN),
    CLIENT_WEB_ORIGIN: parseString(process.env.CLIENT_WEB_ORIGIN),
    IS_PAID_FEATURES_ARE_FREE: parseBoolean(process.env.IS_PAID_FEATURES_ARE_FREE),
    CLIENT_SERVER_CACHE_ROUTES: parseBoolean(process.env.CLIENT_SERVER_CACHE_ROUTES),
    CDN_SERVER: parseString(process.env.CDN_SERVER),
  };
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // if (__DEV__) {
  //   if (typeof window !== 'undefined') {
  //     baseConfig.CORE_SERVER_ORIGIN = setHostname(baseConfig.CORE_SERVER_ORIGIN, window.location.hostname);
  //     baseConfig.CORE_SERVER_WEBSOCKET_ORIGIN = setHostname(baseConfig.CORE_SERVER_WEBSOCKET_ORIGIN, window.location.hostname);
  //   }
  // }
  return Object.freeze(baseConfig);
};

export const config = createConfig();

export { parseBoolean, parseString, parseNumber };
