/* eslint-disable @typescript-eslint/no-magic-numbers */
/* eslint-disable default-case */
/* eslint-disable import/no-duplicates */
import { Day, isSameWeek } from 'date-fns';
import { ru as defaultRU } from 'date-fns/locale';

const accusativeWeekdays = ['воскресенье', 'понедельник', 'вторник', 'среду', 'четверг', 'пятницу', 'субботу'];

const lastWeek = (day: Day): string => {
  const weekday = accusativeWeekdays[day];

  switch (day) {
    case 0:
      return `'в прошлое ${weekday}'`;
    case 1:
    case 2:
    case 4:
      return `'в прошлый ${weekday}'`;
    case 3:
    case 5:
    case 6:
      return `'в прошлую ${weekday}'`;
  }
};

const thisWeek = (day: Day) => {
  const weekday = accusativeWeekdays[day];

  if (day === 2 /* Tue */) {
    return `'во ${weekday}'`;
  } else {
    return `'в ${weekday}'`;
  }
};

const nextWeek = (day: Day) => {
  const weekday = accusativeWeekdays[day];

  switch (day) {
    case 0:
      return `'в следующее ${weekday}'`;
    case 1:
    case 2:
    case 4:
      return `'в следующий ${weekday}'`;
    case 3:
    case 5:
    case 6:
      return `'в следующую ${weekday}'`;
  }
};

const formatRelativeLocale = {
  lastWeek: <DateType extends Date>(date: DateType, baseDate: DateType, options?: any): string => {
    const day = date.getDay() as Day;
    if (isSameWeek(date, baseDate, options)) {
      return thisWeek(day);
    } else {
      return lastWeek(day);
    }
  },
  yesterday: 'вчера',
  today: 'сегодня',
  tomorrow: 'завтра',
  nextWeek: <DateType extends Date>(date: DateType, baseDate: DateType, options?: any): string => {
    const day = date.getDay() as Day;
    if (isSameWeek(date, baseDate, options)) {
      return thisWeek(day);
    } else {
      return nextWeek(day);
    }
  },
  other: 'P',
};

const formatRelative = (token, date, baseDate, options) => {
  const format = formatRelativeLocale[token];

  if (typeof format === 'function') {
    return format(date, baseDate, options);
  }

  return format;
};

export const ru = { ...defaultRU, formatRelative };
