import { ISemanticEvent } from './semanticEvent.interfcace';
import { getSemanticName } from './getSemanticName';
import { EventConfig, EventFactory } from './eventFactory.interface';

/**
 * function creates factory for semantic events
 * @param params default event params or name
 * @param mapper function to transform event payload
 * @return SemanticEvent factory
 */
export const createEventFactory = <T = void>(params: EventConfig, mapper?: (payload: T) => T): EventFactory<T> => {
  const name = getSemanticName(params);

  const eventFactory = (
    payload: T,
    overrideParams: Omit<Partial<EventConfig>, 'name' | 'subject' | 'action' | 'object' | 'result'> = {},
  ) => {
    return {
      name,
      timestamp: Date.now(),
      ...params,
      ...overrideParams,
      payload: mapper ? mapper(payload) : payload,
    };
  };
  eventFactory.eventName = name;
  return eventFactory;
};
