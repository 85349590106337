const s = "md", t = {
  // ignore used for pass ts check in @cp/ds because it adds more breakpoints
  // @ts-ignore
  values: {
    xs: 0,
    sm: 600,
    md: 915,
    lg: 1220,
    xl: 1536,
    mobile: 0,
    tablet: 915,
    desktop: 1220
  }
};
export {
  t as B,
  s as M
};
