export class EventBuffer<T> {
  private _length = 0;
  private _end: Item<T> = null;
  private _start: Item<T> = null;

  constructor(readonly limit: number) {
    this.reset();
  }

  get length() {
    return this._length;
  }

  /**
   * Add value to tail
   * @param value
   */
  public push(value: T) {
    const item = { value, next: null };
    this._length++;
    if (this._start === null || this._end === null) {
      this._start = this._end = item;
      return;
    }

    this._end.next = item;
    this._end = item;
    if (this._length > this.limit) {
      this._start = this._start.next;
      this._length--;
    }
  }

  /**
   * Get value from head
   */
  public shift(): T | null {
    if (!this._start) {
      return null;
    }
    const value = this._start.value;
    this._start = this._start.next;
    this._length--;

    return value;
  }

  /**
   * Drop all values from buffer
   */
  public reset() {
    this._start = this._end = null;
    this._length = 0;
  }

  [Symbol.iterator]() {
    return {
      pointer: this._start,
      next() {
        if (!this.pointer) {
          return { done: true, value: null };
        }
        const res = {
          done: false,
          value: this.pointer.value,
        };
        this.pointer = this.pointer.next;
        return res;
      },
    };
  }
}

type Item<T = any> = {
  value: T;
  next: Item;
} | null;
