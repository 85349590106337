import { formatRelative, getTime } from 'date-fns';
import { ru } from '@cp/shared/lib/date/locale.ru';
import { DialogMessageViewModel } from '@common/model/dialog/message/dialogMessageViewModel';
import { Dictionary, EntityId } from '@reduxjs/toolkit';
import { DateRange } from '../../../shared/lib/date/dateRange';

export type DialogMessageBlockPlain = {
  title: string;
  range: [number, number];
  messages: EntityId[];
};

export class DialogMessageDateBlock {
  readonly title: string;
  readonly messages: DialogMessageViewModel[];

  constructor(readonly range: DateRange, title?: string, baseDate: Date = new Date(), messages: DialogMessageViewModel[] = []) {
    this.title = title || formatRelative(this.range.start || this.range.end || baseDate, baseDate, { locale: ru });
    this.messages = messages;
  }

  canAssignMessage(message: DialogMessageViewModel) {
    return this.range.inRange(new Date(message.createdAt));
  }

  addMessage(message: DialogMessageViewModel) {
    const idx = this.messages.findIndex((m) => m.id === message.id);
    if (idx !== -1) {
      this.messages[idx] = message;
      return;
    }

    if (this.messages[0] && this.messages[0].createdAt > message.createdAt) {
      this.messages.unshift(message);
    } else {
      this.messages.push(message);
    }
  }

  static toPlainObject(block: DialogMessageDateBlock): DialogMessageBlockPlain {
    return {
      title: block.title,
      range: [getTime(block.range.start), getTime(block.range.end)],
      messages: block.messages.map((message) => message.id),
    };
  }

  static fromPlainObject(object: DialogMessageBlockPlain, messagesDict: Dictionary<DialogMessageViewModel>): DialogMessageDateBlock {
    const messages = object.messages.map((id) => messagesDict[id]).filter(Boolean) as DialogMessageViewModel[];
    return new DialogMessageDateBlock(new DateRange(object.range[0], object.range[1]), object.title, undefined, messages);
  }
}
