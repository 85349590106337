import { createTheme as e, useTheme as r } from "@mui/system";
import { ThemeProvider as m } from "@mui/material/styles";
import { s as i } from "./styled-egLbU9Lr.mjs";
import { B as O, M as P } from "./breakpoints-B-27F8sP.mjs";
const t = e, T = r, a = m;
export {
  O as BREAKPOINTS_CONFIG,
  P as MOBILE_BREAKPOINT_THRESHOLD,
  a as ThemeProvider,
  t as createTheme,
  i as styled,
  T as useTheme
};
