import { createBreakpoints as o, Box as a } from "@mui/system";
import { B as p } from "./breakpoints-B-27F8sP.mjs";
import { s as l } from "./styled-egLbU9Lr.mjs";
import { createCssVar as g, cssVar as n } from "./utils.mjs";
const t = {
  white: "#fff",
  black: "#000",
  brandMain: "#3BB273",
  brandLight: "#62CB93",
  brandGreyHover: "#000",
  brandGrey: "#435058",
  greyDark: "#687C88",
  grey: "#7E8C9C",
  greyMiddle: "#CFDEE7",
  greyLight: "#E0EAF0",
  greyUltraLight: "#F5F8FA",
  green: "#6AD059",
  green10: "#F0FAEE",
  red: "#FD562F",
  red10: "#FFEEEA",
  lilac: "#D386EF",
  pink: "#F592BC",
  pinkLight: "#FFE0E4"
}, e = {
  sfProText: '"SF Pro Text", sans-serif',
  sfProDisplay: '"SF Pro Display", sans-serif'
}, s = {
  Hero: {
    fontFamily: e.sfProDisplay,
    fontWeight: 700,
    fontSize: "48px",
    lineHeight: "64px"
  },
  HeroThin: {
    fontFamily: e.sfProDisplay,
    fontSize: "48px",
    lineHeight: "64px"
  },
  H1: {
    fontFamily: e.sfProDisplay,
    fontSize: "30px",
    lineHeight: "36px"
  },
  H2: {
    fontFamily: e.sfProDisplay,
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "30px"
  },
  H3: {
    fontFamily: e.sfProDisplay,
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "24px"
  },
  Button: {
    fontFamily: e.sfProText,
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px"
  },
  ButtonSecondary: {
    fontSize: "16px",
    lineHeight: "24px"
  },
  BodyBold: {
    fontWeight: 500,
    fontSize: "15px",
    lineHeight: "20px"
  },
  Body: {
    fontSize: "15px",
    lineHeight: "20px"
  },
  CaptionBold: {
    fontWeight: 500,
    fontSize: "13px",
    lineHeight: "16px"
  },
  Caption: {
    fontSize: "13px",
    lineHeight: "16px"
  },
  SmallText: {
    fontSize: "12px",
    lineHeight: "14px"
  },
  SmallTextBold: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "14px"
  },
  ExtraSmall: {
    fontWeight: 500,
    fontSize: "9px",
    lineHeight: "13px"
  }
}, d = 420, f = 600, x = 900, h = 1240, y = 12, c = {
  mode: "light",
  divider: t.brandGrey,
  brand: {
    dark: t.brandMain,
    main: t.brandMain,
    light: t.brandLight,
    contrastText: t.white
  },
  background: {
    default: t.white,
    paper: t.greyUltraLight
  },
  text: {
    primary: t.brandGrey,
    secondary: t.grey,
    disabled: t.greyLight,
    // not figma
    accent: t.brandMain
  },
  success: {
    dark: t.green,
    // not figma
    main: t.green,
    light: t.green10,
    contrastText: t.brandGrey
  },
  error: {
    dark: t.red,
    // not figma
    main: t.red,
    light: t.red10,
    contrastText: t.brandGrey
  },
  warning: {
    dark: t.red,
    main: t.red,
    light: t.red10,
    contrastText: t.brandGrey
  },
  info: {
    // not figma
    dark: t.greyLight,
    main: t.greyUltraLight,
    light: t.greyUltraLight,
    contrastText: t.brandGrey
  }
}, E = {
  direction: "ltr",
  breakpoints: o(p),
  palette: c,
  gap: {
    small: 8,
    medium: 12,
    large: 20
  },
  borderRadius: {
    small: 8,
    medium: 12,
    large: 12
  },
  typography: {
    default: { fontFamily: e.sfProText, fontWeight: 400, ...s.Body }
  }
}, r = g("Container"), H = l(a, { name: "TokenProvider" })(({ theme: i }) => ({
  [n("typography", ["color", "accent"])]: i.palette.text.accent,
  [n("Article", "gap")]: i.spacing(2),
  [r("width")]: `${d}px`,
  [r("offset")]: `${y}px`,
  [i.breakpoints.up("sm")]: {
    [r("width")]: `${f}px`
  },
  [i.breakpoints.up("md")]: {
    [r("width")]: `${x}px`
  },
  [i.breakpoints.up("lg")]: {
    [r("width")]: `${h}px`
  }
}));
export {
  H as TokenProvider,
  E as bt
};
