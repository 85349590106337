import { Breakpoint } from '@mui/material/styles';

export const MOBILE_BREAKPOINT_THRESHOLD: Breakpoint = 'md';

export const BREAKPOINTS_CONFIG: Record<Breakpoint, number> = {
  xs: 0,
  cm: 420,
  sm: 600,
  md: 915,
  lg: 1220,
  xl: 1536,
  xxl: 1920,
  mobile: 0,
  tablet: 915,
  laptop: 1220,
  desktop: 1536,
};
