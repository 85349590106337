import { ComponentType, createContext, useContext } from 'react';
import { TrademarkSkeleton } from './trademarkSkeleton';
import { TrademarkProps } from './trademark.interface';

const ctx = createContext<ComponentType<TrademarkProps>>(TrademarkSkeleton);

/**
 * Keep trademark react component
 */
export const TrademarkProvider = ctx.Provider;

/**
 * provide trademark component
 * use in pair with TrademarkProvider
 * @example ```
 *  const MyComponent = () => {
 *    const Trademark = useTrademark();
 *    return (<div>
 *      <Trademark />
 *    </div>);
 *  }
 * ```
 */
export const useTrademarkComponent = () => useContext(ctx);
