import { compareDesc } from 'date-fns';
import { EntityId, createEntityAdapter } from '@reduxjs/toolkit';
import { DialogViewModel } from '@common/model/dialog/dialogViewModel';
import { generateDialogMemberId } from '../../lib';

export type DialogViewModelNormalized = Omit<DialogViewModel, 'members'> & { members: EntityId[]; messages: EntityId[] };

export const dialogsAdapter = createEntityAdapter<DialogViewModelNormalized>({
  sortComparer: (a, b) => compareDesc(new Date(a.lastMessage?.createdAt || a.updatedAt), new Date(b.lastMessage?.createdAt || b.updatedAt)),
});

export const normalizeDialog = (dialog: DialogViewModel): DialogViewModelNormalized => {
  return { messages: [], ...dialog, members: dialog.members.map(generateDialogMemberId) };
};
