import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TrainingAnswers, TrainingAnswersBlock, TrainingBlockId } from '@common/model/client/training/types';

type TrainingState = TrainingAnswers;

const initialState: TrainingState = {};

export const reducerPath = 'clientTraining';

export type ReducerStateShape = { [reducerPath]: TrainingState };

export const slice = createSlice({
  name: reducerPath,
  initialState,
  reducers: {
    addBlock: (state, action: PayloadAction<{ id: TrainingBlockId; answers: TrainingAnswersBlock }>) => {
      state[action.payload.id] = { ...state[action.payload.id], ...action.payload.answers };
    },
    setState: (_, action: PayloadAction<TrainingAnswers>) => {
      return action.payload;
    },
  },
});

export const reducerConfig = {
  [reducerPath]: slice.reducer,
};

export const trainingStateSelector = (state: ReducerStateShape) => state[reducerPath];
export const trainingAnswersBlockSelector = (state: ReducerStateShape, blockId: TrainingBlockId) => state[reducerPath][blockId];
