import { DialogMemberType } from '@common/model/dialog/dialogMemberType';
import { CurrentUserType } from '../../currentUser';

export const mapUserTypeToMemberType = (userType: CurrentUserType): DialogMemberType | null => {
  switch (userType) {
    case CurrentUserType.Client:
      return DialogMemberType.Client;
    case CurrentUserType.Admin:
      return DialogMemberType.Admin;
    default:
      return null;
  }
};
