import { Interval, isWithinInterval, maxTime } from 'date-fns';

export class DateRange implements Interval {
  constructor(
    readonly start: Date | number = 0,
    readonly end: Date | number = maxTime,
  ) {}

  inRange(date: Date) {
    return DateRange.inRange(date, this);
  }

  static inRange(date: Date, range: Interval) {
    return isWithinInterval(date, range);
  }
}
