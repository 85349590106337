import { ComponentType, PropsWithChildren, createContext, useContext } from 'react';

export type HelmetComponent = ComponentType<PropsWithChildren>;

const ctx = createContext<HelmetComponent>(() => {
  console.debug('HelmetContext value is not provided');
  return null;
});
ctx.displayName = 'HelmetContext';
export const HelmetContextProvider = ctx.Provider;
export const useHelmet = () => useContext(ctx);
