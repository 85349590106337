const t = {
  color: "color",
  dimension: "dimension",
  fontFamily: "fontFamily",
  number: "number",
  fontWeight: "fontWeight",
  duration: "duration",
  cubicBezier: "cubicBezier"
}, r = "-", i = (n) => typeof n == "string" ? n.replace(" ", "-") : n.join(r), s = (n, o) => `--${n.toLowerCase()}--${i(o)}`, c = (n, o, e) => `var(${s(n, o)}${e ? ", " + e : ""})`, a = (n) => s.bind(null, n), l = (n) => c.bind(null, n), m = (n, o) => s(t[n], o), b = (n, o, e) => c(t[n], o, e);
export {
  a as createCssVar,
  l as createGetCssVar,
  m as cssToken,
  s as cssVar,
  b as getCssToken,
  c as getCssVar
};
