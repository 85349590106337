import React from 'react';
import { Skeleton } from '@cp/ds/src/components/skeleton';
import { Box } from '@cp/ds/src/components/box';
import { TrademarkProps } from './trademark.interface';

export const TrademarkSkeleton = (props: TrademarkProps) => (
  <Box {...props}>
    <Skeleton>Trademark</Skeleton>
  </Box>
);
