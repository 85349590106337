import React from 'react';
import { SVG_ICON_CACHE_SSR_CONTAINER_ID } from './const';
import { SvgIconCacheContext } from './context';
import { SvgIconCacheStore } from './model';

export const SvgIconCacheLibrary: React.FC<{ store?: SvgIconCacheStore }> = ({ store: externalStore }) => {
  const { store } = React.useContext(SvgIconCacheContext);
  const [ssrContainerRemoved, setSsrContainerRemoved] = React.useState(false);

  React.useEffect(() => {
    for (const container of Array.from(document.querySelectorAll(`#${SVG_ICON_CACHE_SSR_CONTAINER_ID}`))) {
      container.remove();
    }
    setSsrContainerRemoved(true);
  }, []);

  if (typeof window !== 'undefined' && !ssrContainerRemoved) {
    return null;
  }

  const id = typeof window !== 'undefined' ? 'svg-icon-cache-library' : SVG_ICON_CACHE_SSR_CONTAINER_ID;
  const icons = Object.entries(externalStore ?? store);

  return icons.length ? (
    <svg id={id} style={{ opacity: 0, visibility: 'hidden', position: 'absolute', top: -9999, left: -9999 }}>
      {icons.map(([key, { elements }]) => {
        if (!elements) {
          return null;
        }

        // if (Array.isArray(elements)) {
        return (
          <g id={String(key)} key={key}>
            {elements}
          </g>
        );
        // }

        // return React.cloneElement(elements, { key, id: String(key) });
      })}
    </svg>
  ) : null;
};
