import React from 'react';
import { LogoBaseProps, Root } from './base';

export type LogoRectProps = LogoBaseProps;
export const LogoRect = (props: LogoRectProps) => {
  return (
    <Root {...props}>
      <svg fill="none" height="41" viewBox="0 0 132 41" width="132" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#a)">
          <path
            d="M15.51 23.397q0 2.157-2.373 2.93a8.6 8.6 0 0 1-1.7.341 9 9 0 0 1-.937.059H.812q-.8 0-.8-.81v-10.91q0-.8.8-.8h9.405q1.59 0 2.422.186 1.181.263 1.972 1.005.9.83.899 1.963v.967q0 1.133-.801 1.885a.32.32 0 0 0-.117.244q0 .088.058.195l.059.088q.8.743.8 1.875zm-2.227-4.971-.058-1.055q-.068-1.299-2.96-1.299h-7.92v3.516h8.008q2.12 0 2.745-.684a.66.66 0 0 0 .185-.478m-.058 4.228q.02-1.191-2.832-1.191H2.297v3.389h7.94q2.646 0 2.968-1.182zM35.563 25.565q.03.146.03.302 0 .508-.343.82-.342.313-.86.313-.536 0-.966-.625l-2.021-2.95h-9.63l-2.02 2.95a1 1 0 0 1-.186.264.72.72 0 0 1-.518.205q-.654 0-.967-.469a.7.7 0 0 1-.136-.39q0-.128.039-.206L25.2 14.744q.469-.713 1.397-.674 1.104.04 1.318.674zm-5.498-4.024-3.467-5.107-3.486 5.107zM53.663 23.397q0 2.157-2.373 2.93a8.6 8.6 0 0 1-1.7.341 9 9 0 0 1-.937.059h-9.687q-.801 0-.801-.81v-10.91q0-.8.8-.8h9.405q1.591 0 2.422.186 1.181.263 1.972 1.005.9.83.899 1.963v.967q0 1.133-.801 1.885a.32.32 0 0 0-.117.244q0 .088.058.195l.059.088q.8.743.8 1.875zm-2.227-4.971-.058-1.055q-.068-1.299-2.96-1.299h-7.92v3.516h8.009q2.118 0 2.744-.684a.66.66 0 0 0 .185-.478m-.058 4.228q.02-1.191-2.832-1.191H40.45v3.389h7.94q2.646 0 2.968-1.182zM70.718 15.584l-6.162 5.918-.02 4.414q0 .81-.8.81h-.665q-.81 0-.81-.8v-4.424l-6.172-5.918q-.36-.342-.361-.703 0-.675.927-.674h.703q.322 0 .557.225l5.479 5.254 5.498-5.254a.78.78 0 0 1 .556-.225h.713q.918 0 .918.674 0 .352-.361.703"
            fill="#3BB273"
          />
          <path
            d="M86.064 15.3q0 .81-.81.81h-5.06v9.844q0 .81-.8.81h-.674q-.8 0-.8-.81V16.11h-5.03q-.81 0-.81-.81v-.254q0-.8.81-.8h12.363q.81 0 .81.8zM90.267 25.954q0 .81-.81.81h-.674q-.801 0-.801-.81V15.046q0-.8.8-.8h.675q.81 0 .81.8zM114.627 25.954q0 .81-.801.81h-.683q-.801 0-.801-.81v-8.096l-6.729 8.301q-.537.664-1.445.664-.918 0-1.465-.664l-6.855-8.34v8.135q0 .81-.811.81h-.674q-.8 0-.8-.81V15.446q0-.517.42-.86.39-.321.927-.321.732 0 1.26.625l8.017 9.482 7.832-9.502q.479-.576 1.348-.576 1.26 0 1.26 1.25zM131.985 20.476l-.02.263q-.059.762-.801.762h-10.986v2.06q0 1.329 2.93 1.329h8.056q.801 0 .801.81v.254q0 .81-.801.81H123q-.38 0-.947-.058-4.16-.429-4.16-3.066v-6.201q0-1.758 2.041-2.618 1.367-.576 3.262-.576h2.226q.801 0 .801.8v.255q0 .81-.801.81h-2.314l-.635.04q-2.325.146-2.324 1.289v2.187h11.025q.87 0 .811.85"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path d="M.012.534h131.977v40H.012z" fill="#fff" />
          </clipPath>
        </defs>
      </svg>
    </Root>
  );
};
