import { EntityId, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { currentClientFavoriteApi } from '../api';

export type FavoritesState = EntityId[];

const initialState: FavoritesState = [];
export const reducerPath = 'clientFavorites';
export type FavoritesReducerStateShape = { [reducerPath]: FavoritesState };

export const slice = createSlice({
  name: 'clientFavorites',
  initialState,
  reducers: {
    toggleFavorite: (state, action: PayloadAction<EntityId>) => {
      const clientId = action.payload;
      if (state.includes(clientId)) {
        return state.filter((id) => id !== clientId);
      }
      state.push(clientId);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(currentClientFavoriteApi.endpoints.getFavoriteClientsList.matchFulfilled, (_, { payload }) => {
      return payload.map((clientProfile) => clientProfile.id);
    });
  },
});

export const clientFavoritesReducerConfig = {
  [reducerPath]: slice.reducer,
};
