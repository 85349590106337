import { createEventFactory } from '../createEventFactory';

export type PageViewEventPayload = {
  page: {
    url: string;
    path: string;
    title: string;
    queryString?: string;
    referrer?: string;
  };
};

export const createPageViewEvent = createEventFactory<PageViewEventPayload>({
  subject: 'page',
  action: 'view',
});
