import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LanguageCode } from '@common/model/resource/language';
import { DEFAULT_LANGUAGE_CODE } from '@common/const/localization';

export interface LocalizationState {
  languageCode: LanguageCode;
}

const initialState: LocalizationState = {
  languageCode: DEFAULT_LANGUAGE_CODE,
};

export const localizationReducerPath = 'localization';
export type LocalizationSliceShape = { [localizationReducerPath]: LocalizationState };

export const localizationSlice = createSlice({
  name: '@@app/localization',
  initialState,
  reducers: {
    setLanguageCode(state: LocalizationState, action: PayloadAction<LocalizationState['languageCode']>) {
      state.languageCode = action.payload;
    },
  },
});
