import { compareAsc } from 'date-fns';
import { DialogMessageViewModel } from '@common/model/dialog/message/dialogMessageViewModel';
import { DialogMessageDateBlock, createBlockForMessage } from '@cp/entities/dialog';

export const addMessagesToBlocks = (
  messages: DialogMessageViewModel[],
  blocks: DialogMessageDateBlock[] = [],
): DialogMessageDateBlock[] => {
  let block: DialogMessageDateBlock | undefined;
  // TODO: actually we can check only first and last blocks
  for (const message of messages) {
    block = blocks.find((b) => b.canAssignMessage(message));
    if (block) {
      block.addMessage(message);
    } else {
      block = createBlockForMessage(message);
      block.addMessage(message);
      blocks.push(block);
    }
  }
  return blocks.sort((a, b) => compareAsc(a.range.start, b.range.start));
};
