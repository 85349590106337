import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CATALOG_DESKTOP_VIEW_MODE_DEFAULT, CatalogViewMode } from './viewMode';

export interface CatalogLayoutState {
  isMapRequested: boolean;
  desktopViewMode: CatalogViewMode;
  activeItemId: number | undefined;
  isFilterSecondOpened: boolean;
  mapInstance?: unknown;
  viewModeSwitchHeight: number;
  contactRequestsCount: number;
}

const initialState: CatalogLayoutState = {
  isMapRequested: false,
  desktopViewMode: CATALOG_DESKTOP_VIEW_MODE_DEFAULT,
  activeItemId: undefined,
  isFilterSecondOpened: false,
  mapInstance: undefined,
  viewModeSwitchHeight: 0,
  contactRequestsCount: 0,
};
const reducerPath = 'catalog';
export type CatalogStateShape = { [reducerPath]: CatalogLayoutState };

export const catalogLayoutSlice = createSlice({
  name: '@@app/catalog/layout',
  initialState,
  reducers: {
    setMapRequested(state, action: PayloadAction<CatalogLayoutState['isMapRequested']>): void {
      state.isMapRequested = action.payload;
    },
    setDesktopViewMode(state, action: PayloadAction<CatalogLayoutState['desktopViewMode']>): void {
      state.desktopViewMode = action.payload;

      if (action.payload === 'map') {
        state.isMapRequested = true;
      }
    },
    setActiveItem(state, action: PayloadAction<CatalogLayoutState['activeItemId']>): void {
      state.activeItemId = action.payload;
    },
    setFilterSecondOpened(state, action: PayloadAction<CatalogLayoutState['isFilterSecondOpened']>): void {
      state.isFilterSecondOpened = action.payload;
    },
    setMapInstance(state, action: PayloadAction<CatalogLayoutState['mapInstance']>): void {
      state.mapInstance = action.payload;
    },
    setViewModeSwitchHeight(state, action: PayloadAction<CatalogLayoutState['viewModeSwitchHeight']>): void {
      state.viewModeSwitchHeight = action.payload;
    },
    setContactRequestsCount(state): void {
      state.contactRequestsCount = state.contactRequestsCount + 1;
    },
  },
});

export const reducerConfig = {
  [reducerPath]: catalogLayoutSlice.reducer,
};
