import { ApiRoute } from './route';
import { ApiRoutePathParams } from './routePathParams';
import { apiRouteUrlDict } from './routeUrl';

export const generateApiUrl = <T extends ApiRoute>(
  route: T,
  params?: T extends keyof ApiRoutePathParams ? ApiRoutePathParams[T] : never,
) => {
  if (!params) {
    return apiRouteUrlDict[route];
  }

  return Object.keys(params as object).reduce((acc, key) => acc.replace(`:${key}`, String(params[key])), apiRouteUrlDict[route] as string);
};
