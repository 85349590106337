import React from 'react';
import { Box as MuiBox } from '@mui/material';
// import { OverridableComponent } from '@mui/material/OverridableComponent';
import { BoxProps /*, BoxTypeMap */ } from './model';
export type { BoxProps } from './model';

// export const Box = React.forwardRef<unknown | undefined, BoxProps>((props: BoxProps, ref) => {
//   return <MuiBox {...props} ref={ref} />;
// }) as OverridableComponent<BoxTypeMap>;

export const Box = React.forwardRef<HTMLElement, React.PropsWithChildren<BoxProps>>((props: BoxProps, ref) => {
  return <MuiBox component="div" {...props} ref={ref} />;
});
