import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ClientRegistrationState } from '@common/model/client/registration/registrationState';

type RegistrationState = {
  state: Partial<ClientRegistrationState>;
  token: string;
};

const initialState: RegistrationState = {
  token: '',
  state: {},
};

export const reducerPath = 'registration';

export type ReducerStateShape = { [reducerPath]: RegistrationState };

export const slice = createSlice({
  name: reducerPath,
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setState: (state, action: PayloadAction<Partial<ClientRegistrationState>>) => {
      state.state = action.payload;
    },
    clear: () => {
      return initialState;
    },
  },
});

export const reducerConfig = {
  [reducerPath]: slice.reducer,
};

export const registrationTokenSelector = (state: ReducerStateShape) => state[reducerPath].token;
export const registrationStateSelector = (state: ReducerStateShape) => state[reducerPath].state;
