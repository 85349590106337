import { LegalConfig } from 'src/widgets/legal';

export const LEGAL: LegalConfig = {
  BANK_NAME: 'АО "ТИНЬКОФФ БАНК"',
  BIC: '044525974',
  CHECKING_ACCOUNT: '40702810110001596784',
  CORRESPONDENT_ACCOUNT: '30101810145250000974',
  DIRECTOR_NAME: 'Самсыкина Виолетта Евгеньевна',
  INN: '6141061423',
  KPP: '614101001',
  LEGAL_ADDRESS: '346882, Ростовская область, г Батайск, Ясеневая ул, д. 13, кв 3',
  POSTAL_ADDRESS: '346882, Ростовская область, г Батайск, Ясеневая ул, д. 13, кв 3',
  LEGAL_NAME: 'ООО «БЕБИТАЙМ»',
  LEGAL_NAME_FULL: 'Общество с ограниченной ответственностью «БЕБИТАЙМ»',
  OGRN: '1246100009968',
  OKPO: '59587064',
  PHONE: '8(800)301 77 64',
};
