import { IEvent, IEventBus } from '@cp/shared/cqrs';

const EVENT_NAME = 'event-bus';

export class EventBus extends EventTarget implements IEventBus {
  publish<T extends IEvent>(event: T): void {
    this.dispatchEvent(new CustomEvent<T>(EVENT_NAME, { detail: event }));
  }
  subscribe(cb: (event: IEvent) => void): void {
    this.addEventListener(EVENT_NAME, (event) => cb((event as CustomEvent).detail));
  }
  unsubscribe(cb: (event: IEvent) => void): void {
    this.removeEventListener(EVENT_NAME, cb);
  }
}
