export const ADMIN_EMAIL = 'help@babytime.ru';
export const EMAIL_FOR_ADVERT = 'advertising@babytime.ru';
export const EMAIL_FOR_CLAIMS = 'control@babytime.ru';
export const EMAIL_FOR_JOB_VACANCIES = 'vacancy@babytime.ru';
export const SUPPORT_PHONE = '78003017764';
export const PHONE_FOR_CLAIMS = '88003017764';
export const PHONE_FOR_JOB_VACANCIES = '88003017764';

export const CONTACTS_CONFIG = {
  ADMIN_EMAIL,
  EMAIL_FOR_ADVERT,
  EMAIL_FOR_CLAIMS,
  EMAIL_FOR_JOB_VACANCIES,
  SUPPORT_PHONE,
  PHONE_FOR_CLAIMS,
  PHONE_FOR_JOB_VACANCIES,
};

export type ContactsConfig = typeof CONTACTS_CONFIG;
