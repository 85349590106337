import React, { PropsWithChildren, useMemo } from 'react';
import { ConfigContextProvider, ConfigTypeOverride } from '@cp/shared/config';
import { createConfigManager } from './createConfig';

export const ConfigProvider = (props: PropsWithChildren & { config: Partial<ConfigTypeOverride> }) => {
  const manager = useMemo(() => {
    return createConfigManager(props.config);
  }, [props.config]);
  return <ConfigContextProvider value={manager}>{props.children}</ConfigContextProvider>;
};
