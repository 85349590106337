import { EntityId } from '@reduxjs/toolkit';

export const insertUniqueIds = (ids: EntityId[], id: EntityId | EntityId[]): EntityId[] => {
  if (Array.isArray(id)) {
    let acc = ids;
    for (const _id of id) {
      acc = insertUniqueIds(acc, _id);
    }
    return acc;
  }
  if (ids.includes(id)) {
    return ids;
  }
  return ids.concat([id]);
};
