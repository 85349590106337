import { ConfigError, ConfigType, IConfigManager } from '@cp/shared/config';

export class ConfigManager<C extends ConfigType> implements IConfigManager<C> {
  readonly #sources: Array<Partial<C>> = [];
  constructor(...sources: Partial<C>[]) {
    this.#sources = sources.reverse();
  }

  get<T extends keyof C | string>(key: T): T extends keyof C ? C[T] | null : null;
  get<T extends keyof C | string, V = T extends keyof C ? NonNullable<C[T]> : never>(
    key: T,
    def: V,
  ): T extends keyof C ? NonNullable<C[T]> : V;
  get<T extends keyof C | string, V = T extends keyof C ? NonNullable<C[T]> : never>(key: T, def?: V) {
    const src = this._lookup(key);
    return src ? src[key] : def || null;
  }

  getOrThrow<T extends keyof C | string, R extends T extends keyof C ? C[T] : never>(key: T): R {
    const src = this._lookup(key);
    if (!src) {
      throw ConfigError.NotFound(key);
    }
    return src[key] as R;
  }

  private _lookup(key: PropertyKey) {
    return this.#sources.find((src) => src.hasOwnProperty(key));
  }
}
