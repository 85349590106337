import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface ModalViewState {
  openedViews: Record<string, true>;
}

const initialState: ModalViewState = {
  openedViews: {},
};

export const modalViewReducerPath = 'modalView' as const;
export type ModalViewSliceShape = { [modalViewReducerPath]: ModalViewState };

export const modalViewSlice = createSlice({
  name: '@@app/ds/modalView',
  initialState,
  reducers: {
    trackViewOpened(state, action: PayloadAction<string>) {
      state.openedViews[action.payload] = true;
    },
    trackViewClosed(state, action: PayloadAction<string>) {
      delete state.openedViews[action.payload];
    },
  },
});

export const modalViewReducerConfig = {
  [modalViewReducerPath]: modalViewSlice.reducer,
};
