import { createContext, useContext } from 'react';
import { ConfigType, IConfigManager } from './manager.interface';
import { ConfigError } from './errors';

const ctx = createContext<IConfigManager>({
  // @ts-expect-error
  get: (_, def) => def || null,
  getOrThrow: (key) => {
    throw ConfigError.NotFound(key);
  },
});
ctx.displayName = 'ConfigContext';
export const ConfigContextProvider = ctx.Provider;

export const useConfig = <T = ConfigType, C extends ConfigType = ConfigType>(): IConfigManager<T & C> => {
  return useContext(ctx) as unknown as IConfigManager<C & T>;
};
