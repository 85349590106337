import { createTheme } from '@mui/material/styles';
import { bt } from '@cp/ui/themes/bt';
import { BREAKPOINTS_CONFIG } from './const';
import { fontFace } from './fonts';
import { AppThemeName } from './model';

const fontFamily =
  '"SF Pro Text", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif';

// Create a theme instance.
export const createThemeBT = (
  name: AppThemeName,
  options?: {
    ssrMatchMedia?: (query: string) => {
      matches: boolean;
    };
  },
) =>
  createTheme({
    breakpoints: {
      values: { ...BREAKPOINTS_CONFIG, ...(bt.breakpoints && bt.breakpoints?.values) },
    },
    borderRadius: {
      ...bt.borderRadius,
      extraSmall: 4,
      small: 8,
      normal: 12,
      medium: 16,
      large: 20,
      extraLarge: 24,
    },
    palette: {
      ...bt.palette,
      text: {
        ...bt.palette.text,
        primary: '#405368',
        secondary: '#7E8C9C',
        disabled: '#D6DEE2',
      },
      background: {
        ...bt.palette.background,
        default: '#fff',
        paper: '#F5F8FA', // equal to "Background" color in figma design (used for back compatibility with MUI components, which uses background colors)
      },
      social: {
        ok: '#ED810D',
        vk: '#4C6C91',
        whatsapp: '#25D366',
      },
      'transparent': 'transparent',
      'brand pink hover': '#62CB93',
      'brand pink': '#3BB273',
      peach: '#E0EAF0',
      'brand gray hover': '#000000',
      'brand gray': '#435058',
      'gray dark': '#687C88',
      gray: '#7E8C9C',
      'gray background': '#F0F0F4',
      'gray middle': '#CFDEE7',
      'gray light': '#E0EAF0',
      'gray ultra light': '#F5F8FA',
      lilac: '#D386EF',
      pink: '#F592BC',
      'pink light': '#FFE0E4',
      green: '#6AD059',
      blue: '#CFE4F0',
      white: '#FFFFFF',
      black: '#000000',
      vk: '#4A85CA',
      ok: '#EF8109',
      apple: '#0B0B0A',
    },
    typography: {
      fontFamily,
      h0: {
        fontSize: '3rem',
        lineHeight: 1.33333333,
        fontWeight: 700,
      },
      'h0 thin': {
        fontSize: '3rem',
        lineHeight: 1.33333333,
        fontWeight: 400,
      },
      h1: {
        fontSize: '1.875rem',
        lineHeight: 1.2,
        fontWeight: 400,
      },
      'h1 thin': {
        fontSize: '1.875rem',
        lineHeight: 1.2,
        fontWeight: 500,
      },
      h2: {
        fontSize: '1.5rem',
        lineHeight: 1.25,
        fontWeight: 500,
      },
      'h2 thin': {
        fontSize: '1.5rem',
        lineHeight: 1.25,
        fontWeight: 500,
      },
      h3: {
        fontSize: '1.125rem',
        lineHeight: 1.333333333333333,
        fontWeight: 500,
      },
      'h3 thin': {
        fontSize: '2.25rem',
        lineHeight: 1.111111111,
        fontWeight: 500,
      },
      h4: {
        fontSize: '1.875rem',
        lineHeight: 1.2,
        fontWeight: 800,
      },
      'h4 thin': {
        fontSize: '1.875rem',
        lineHeight: 1.2,
        fontWeight: 500,
      },
      h5: {
        fontSize: '1.5rem',
        lineHeight: 1.291666667,
        fontWeight: 800,
      },
      'h5 thin': {
        fontSize: '1.5rem',
        lineHeight: 1.291666667,
        fontWeight: 500,
      },
      h6: {
        fontSize: '1.25rem',
        lineHeight: 1.4,
        fontWeight: 500,
      },
      'h6 Bold': {
        fontSize: '1.25rem',
        lineHeight: 1.2,
        fontWeight: 800,
      },
      button: {
        fontSize: '1rem',
        lineHeight: 1.5,
        fontWeight: 500,
        textTransform: 'none',
      },
      'button bold': {
        fontSize: '1rem',
        lineHeight: 1.5,
        fontWeight: 700,
        textTransform: 'none',
      },
      'button secondary': {
        fontSize: '1rem',
        lineHeight: 1.33333333,
        fontWeight: 400,
      },
      body: {
        fontSize: '0.938rem',
        lineHeight: 1.5,
        fontWeight: 400,
      },
      'body bold': {
        fontSize: '0.938rem',
        lineHeight: 1.33333333,
        fontWeight: 500,
      },
      caption: {
        fontSize: '0.813rem',
        lineHeight: 1.230769230769231,
        fontWeight: 400,
      },
      'caption bold': {
        fontSize: '0.813rem',
        lineHeight: 1.230769230769231,
        fontWeight: 500,
      },
      'small text': {
        fontSize: '0.75rem',
        lineHeight: 1.666666667,
        fontWeight: 400,
      },
      'small text bold': {
        fontSize: '0.75rem',
        lineHeight: 1.166666666666667,
        fontWeight: 500,
      },
      extraSmall: {
        fontSize: '0.563rem',
        lineHeight: 1.444444444444444,
        fontWeight: 500,
      },

      // Disable variants
      subtitle2: undefined,
      body1: undefined,
      body2: undefined,
      overline: undefined,
      subtitle1: undefined,
    },
    components: {
      // Change the default options of useMediaQuery
      MuiUseMediaQuery: {
        defaultProps: {
          ssrMatchMedia: options?.ssrMatchMedia,
        },
      },
      // Change base style overrides
      MuiCssBaseline: {
        styleOverrides:
          fontFace +
          `
            ::selection {
                background: #ffe2e0;
                text-shadow: none;
            }
            * {
                font-family: ${fontFamily};
            }
          `,
      },
      MuiBadge: {
        styleOverrides: {
          anchorOriginTopRightRectangular: 'top: 2px',
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: ({ theme }) => ({
            ...theme.typography.body,
            color: theme.palette.text.secondary,
            '&:hover': {
              color: theme.palette.text.primary,
              background: theme.palette['gray ultra light'],
            },
          }),
          selected: ({ theme }) => ({
            color: theme.palette.text.primary,
            background: theme.palette['gray ultra light'],
          }),
        },
      },
      MuiListItemText: {
        styleOverrides: {
          multiline: ({ theme }) => ({
            '& .MuiListItemText-primary': theme.typography['body bold'],
          }),
        },
      },
      MuiDrawer: {
        styleOverrides: {
          root: { position: 'fixed' },
        },
      },
    },
  });
