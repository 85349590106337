import { IEventBus } from '@cp/shared/cqrs';
import { createPageViewEvent } from '@cp/shared/tracking';

export class AppService {
  #eventBus: IEventBus;
  constructor(eventBus: IEventBus) {
    this.#eventBus = eventBus;
  }

  public sendPageView(page: { url: string; title: string; type: string; path: string }) {
    this.#eventBus.publish(createPageViewEvent({ page }));
  }
}
