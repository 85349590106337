export enum AppHeaders {
  LanguageCode = 'language-code',
  SessionId = 'session-id',
  Authorization = 'authorization',
  RegistrationSessionId = 'registration-session-id',
}

export enum AppCookies {
  Authorization = '_id',
}
