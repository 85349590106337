export enum LanguageCode {
  Russian = 'ru-RU',
  English = 'en-US',
}

export const AVAILABLE_LANGUAGE_CODES = Object.values(LanguageCode);

/**
 * Convert language code to ISO 639-1
 * @see {@link https://en.wikipedia.org/wiki/ISO_639-1}
 * @param code
 * @example ru-RU -> ru
 */
export const languageCodeToISO = (code: LanguageCode) => {
  return code.substring(0, 2);
};

/**
 * Convert language code to locale format
 * @param code
 * @example ru-RU -> ru_RU
 *
 */
export const languageCodeToLocale = (code: LanguageCode) => {
  return code.replace('-', '_');
};
