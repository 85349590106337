import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface CitySelectionState {
  isOpen: boolean;
}

const initialState: CitySelectionState = {
  isOpen: false,
};

const reducerPath = 'citySelection';

export type CitySelectionStateShape = { [reducerPath]: CitySelectionState };

export const slice = createSlice({
  name: '@@app/citySelection',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<CitySelectionState['isOpen']>) => {
      state.isOpen = action.payload;
    },
  },
});

export const reducerConfig = {
  [reducerPath]: slice.reducer,
};

export const citySelectionIsOpenSelector = (state: CitySelectionStateShape) => state[reducerPath].isOpen;
