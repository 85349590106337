import { ConfigureStoreOptions } from '@reduxjs/toolkit';
// @ts-expect-error import path error
import { setupListeners } from '@reduxjs/toolkit/query';
import { configureAppStore } from './configureStore';

export const configureClientSideStore = ({ devTools = true, ...options }: Partial<ConfigureStoreOptions>) => {
  const store = configureAppStore(Object.assign(options, { devTools }));
  setupListeners(store.dispatch);
  return store;
};
