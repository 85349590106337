/* eslint-disable @typescript-eslint/no-magic-numbers */
import React from 'react';
import { styled } from '@cp/ds/theme/styled';
import { LogoBaseProps, Root } from './base';

type LogoSize = 'large' | 'normal' | 'small';

const SIZES: Record<LogoSize, [number, number, number]> = {
  large: [80, 7, 11],
  normal: [60, 3, 6],
  small: [40, 3, 6],
};

const SquareRoot = styled(Root)<LogoSquareProps>(({ size }) => ({
  width: `var(--dimension--size, ${SIZES.normal[0]}px)`,
  height: 'var(--dimension--size)',
  ...(size && {
    '--dimension--size': `${SIZES[size][0]}px`,
    '--dimension--offset': `${SIZES[size][1]}px`,
    '--dimension--radius': `${SIZES[size][2]}px`,
  }),
}));

export interface LogoSquareProps extends LogoBaseProps {
  size?: LogoSize;
}

export const LogoSquare = (props: LogoSquareProps) => {
  return (
    <SquareRoot {...props}>
      <svg fill="none" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.558 23.213q0 1.67-1.77 2.266-.539.18-1.267.264-.4.046-.7.046H3.598q-.597 0-.597-.627v-8.435q0-.62.597-.62h7.014q1.187 0 1.806.144.881.204 1.47.778.671.64.671 1.518v.747q0 .876-.597 1.458a.25.25 0 0 0-.088.188q0 .069.044.151l.044.068q.597.574.597 1.45zm-1.66-3.843-.044-.816q-.051-1.005-2.207-1.004H4.741v2.718h5.972q1.58 0 2.046-.528a.52.52 0 0 0 .139-.37m-.044 3.27q.015-.922-2.112-.922H4.704v2.62h5.921q1.974 0 2.214-.913zM29.513 24.89q.021.113.022.234a.83.83 0 0 1-.255.634.9.9 0 0 1-.641.242q-.4 0-.721-.483l-1.508-2.28h-7.18l-1.508 2.28a.7.7 0 0 1-.138.204.53.53 0 0 1-.386.158q-.489 0-.721-.362a.56.56 0 0 1-.102-.302q0-.099.029-.159l5.382-8.533q.35-.552 1.041-.521.824.03.983.52zm-4.1-3.111-2.586-3.95-2.6 3.95zM43.011 23.213q0 1.67-1.77 2.266-.538.18-1.267.264-.4.046-.699.046h-7.224q-.598 0-.598-.627v-8.435q0-.62.598-.62h7.013q1.187 0 1.806.144.882.204 1.471.778.67.64.67 1.518v.747q0 .876-.597 1.458a.25.25 0 0 0-.087.188.3.3 0 0 0 .044.151l.043.068q.597.574.597 1.45zm-1.66-3.843-.044-.816q-.051-1.005-2.207-1.004h-5.906v2.718h5.972q1.58 0 2.047-.528a.52.52 0 0 0 .138-.37m-.044 3.27q.015-.922-2.112-.922h-6.037v2.62h5.92q1.974 0 2.215-.913zM55.73 17.172l-4.595 4.576-.014 3.414q0 .627-.598.627h-.495q-.605 0-.605-.62v-3.42l-4.602-4.577q-.27-.264-.27-.544 0-.52.692-.52h.524q.241 0 .416.173l4.085 4.063 4.1-4.063a.57.57 0 0 1 .416-.174h.531q.685 0 .685.521 0 .273-.27.544"
          fill="#3BB273"
        />
        <path
          d="M14.035 34.839q0 .645-.64.644H9.404v7.826q0 .644-.632.644H8.24q-.632 0-.632-.644v-7.826H3.64q-.64 0-.64-.644v-.202Q3 34 3.64 34h9.755q.64 0 .64.637zM17.352 43.309q0 .644-.64.644h-.532q-.632 0-.632-.644v-8.672q0-.637.632-.637h.532q.64 0 .64.637zM36.573 43.309q0 .644-.632.644h-.54q-.63 0-.63-.644v-6.436l-5.31 6.599q-.424.528-1.14.528-.726 0-1.157-.528l-5.409-6.63v6.467q0 .644-.64.644h-.531q-.633 0-.632-.644v-8.354q0-.411.331-.683.309-.256.732-.256.579 0 .994.496l6.327 7.54 6.18-7.555q.378-.459 1.063-.458.994 0 .994.994zM50.27 38.953l-.016.21q-.046.606-.631.606h-8.67v1.638q0 1.056 2.312 1.056h6.358q.63 0 .631.644v.202q0 .644-.631.644H43.18q-.3 0-.748-.046-3.283-.342-3.283-2.438v-4.93q0-1.398 1.61-2.08Q41.84 34 43.336 34h1.757q.63 0 .631.637v.202q0 .645-.631.644h-1.827l-.5.031q-1.835.117-1.835 1.025v1.739h8.7q.685 0 .64.675"
          fill="currentColor"
        />
      </svg>
    </SquareRoot>
  );
};
