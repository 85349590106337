import { ClientCatalogItemViewModel } from '@common/model/client/catalog/item/clientCatalogItemViewModel';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface MainResumeState {
  viewData: ClientCatalogItemViewModel | undefined;
}

const initialState: MainResumeState = {
  viewData: undefined,
};

export const mainResumeReducerPath = 'mainResume';
export type MainResumeSliceShape = { [mainResumeReducerPath]: MainResumeState };

export const mainResumeSlice = createSlice({
  name: '@@app/entities/mainResume',
  initialState,
  reducers: {
    setViewData(state, action: PayloadAction<MainResumeState['viewData']>) {
      state.viewData = action.payload;
    },
  },
});

export const mainResumeReducerConfig = {
  [mainResumeReducerPath]: mainResumeSlice.reducer,
};
