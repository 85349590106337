import { BotIdentificator } from '../bot';
import { DialogMemberType } from './dialogMemberType';

type CurrentUser = { id: number; type: DialogMemberType };

/**
 * Function allows admin to mimic support bot,
 * read its messages and send messages as bot
 */
export const mapCurrentUserToDialogUser = (currentUser: CurrentUser): CurrentUser => {
  if (currentUser.type !== DialogMemberType.Admin) {
    return currentUser;
  }
  return { id: BotIdentificator.SupportBot, type: DialogMemberType.Bot };
};
