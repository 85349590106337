/* eslint-disable max-len */
import MPlusThinWoff2 from '../assets/fonts/mplusrounded1c/Roundedmplus1cthin.woff2';
import MPlusThinWoff from '../assets/fonts/mplusrounded1c/Roundedmplus1cthin.woff';
import MPlusThinTtf from '../assets/fonts/mplusrounded1c/Roundedmplus1cthin.ttf';
import MPlusLightWoff2 from '../assets/fonts/mplusrounded1c/Roundedmplus1clight.woff2';
import MPlusLightWoff from '../assets/fonts/mplusrounded1c/Roundedmplus1clight.woff';
import MPlusLightTtf from '../assets/fonts/mplusrounded1c/Roundedmplus1clight.ttf';
import MPlusRegularWoff2 from '../assets/fonts/mplusrounded1c/Roundedmplus1c.woff2';
import MPlusRegularWoff from '../assets/fonts/mplusrounded1c/Roundedmplus1c.woff';
import MPlusRegularTtf from '../assets/fonts/mplusrounded1c/Roundedmplus1c.ttf';
import MPlusMediumWoff2 from '../assets/fonts/mplusrounded1c/Roundedmplus1cmedium.woff2';
import MPlusMediumWoff from '../assets/fonts/mplusrounded1c/Roundedmplus1cmedium.woff';
import MPlusMediumTtf from '../assets/fonts/mplusrounded1c/Roundedmplus1cmedium.ttf';
import MPlusBoldWoff2 from '../assets/fonts/mplusrounded1c/Roundedmplus1cbold.woff2';
import MPlusBoldWoff from '../assets/fonts/mplusrounded1c/Roundedmplus1cbold.woff';
import MPlusBoldTtf from '../assets/fonts/mplusrounded1c/Roundedmplus1cbold.ttf';
import MPlusExtraBoldWoff2 from '../assets/fonts/mplusrounded1c/Roundedmplus1cextrabold.woff2';
import MPlusExtraBoldWoff from '../assets/fonts/mplusrounded1c/Roundedmplus1cextrabold.woff';
import MPlusExtraBoldTtf from '../assets/fonts/mplusrounded1c/Roundedmplus1cextrabold.ttf';
import MPlusBlackWoff2 from '../assets/fonts/mplusrounded1c/Roundedmplus1cblack.woff2';
import MPlusBlackWoff from '../assets/fonts/mplusrounded1c/Roundedmplus1cblack.woff';
import MPlusBlackTtf from '../assets/fonts/mplusrounded1c/Roundedmplus1cblack.ttf';

export const preloadableFonts: string[] = [MPlusRegularWoff2, MPlusMediumWoff2, MPlusBoldWoff2];

export const fontFace = `
  @font-face {
    font-family: 'M PLUS Rounded 1c';
    src: local('Rounded Mplus 1c Thin'), local('RoundedMplus1c-Thin'), url('${MPlusThinWoff2}') format('woff2'), url('${MPlusThinWoff}') format('woff'), url('${MPlusThinTtf}') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'M PLUS Rounded 1c';
    src: local('Rounded Mplus 1c Light'), local('RoundedMplus1c'), url('${MPlusLightWoff2}') format('woff2'), url('${MPlusLightWoff}') format('woff'), url('${MPlusLightTtf}') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'M PLUS Rounded 1c';
    src: local('Rounded Mplus 1c'), local('RoundedMplus1c-Regular'), url('${MPlusRegularWoff2}') format('woff2'), url('${MPlusRegularWoff}') format('woff'), url('${MPlusRegularTtf}') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'M PLUS Rounded 1c';
    src: local('Rounded Mplus 1c Medium'), local('RoundedMplus1c-Medium'), url('${MPlusMediumWoff2}') format('woff2'), url('${MPlusMediumWoff}') format('woff'), url('${MPlusMediumTtf}') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'M PLUS Rounded 1c';
    src: local('Rounded Mplus 1c Bold'), local('RoundedMplus1c-Bold'), url('${MPlusBoldWoff2}') format('woff2'), url('${MPlusBoldWoff}') format('woff'), url('${MPlusBoldTtf}') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'M PLUS Rounded 1c';
    src: local('Rounded Mplus 1c ExtraBold'), local('RoundedMplus1c-ExtraBold'), url('${MPlusExtraBoldWoff2}') format('woff2'), url('${MPlusExtraBoldWoff}') format('woff'), url('${MPlusExtraBoldTtf}') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'M PLUS Rounded 1c';
    src: local('Rounded Mplus 1c Black'), local('RoundedMplus1c-Black'), url('${MPlusBlackWoff2}') format('woff2'), url('${MPlusBlackWoff}') format('woff'), url('${MPlusBlackTtf}') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }
`;
