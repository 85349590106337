import { SemanticContext, Spider, createPageViewEvent } from '@cp/shared/tracking';
import { IConfigManager } from '@cp/shared/config';
import { IEvent } from '@cp/shared/cqrs';
import { yandexMetrikaFactory } from './integrations/yandexMetrika.factory';
import { IntegrationFactory } from './integrations/factory';

export class SpiderService {
  #logger = console;
  #spider: Spider;
  #configManager: IConfigManager;
  #ctx: SemanticContext;

  constructor(configManager: IConfigManager) {
    this.#configManager = configManager;
    this.#ctx = this.initCtx();
    this.#spider = new Spider(3, this.#ctx);
    this.initIntegrations();
  }

  private initIntegrations() {
    const global = window;
    SpiderService.getIntegrationsFactories(this.#configManager).map((createIntegration) => {
      createIntegration(this.#configManager, global, this.#ctx)
        .then((integration) => {
          this.#logger.info('Loaded integration', integration.displayName);
          this.#spider.addIntegration(integration);
        })
        .catch((result) => {
          this.#logger.warn('Can not load integration', result.reason.message);
        });
    });
  }

  private _onMessageReceived(e: IEvent) {
    // eslint-disable-next-line default-case
    switch (e.name) {
      case createPageViewEvent.eventName:
        this.#spider.trackEvent(e as ReturnType<typeof createPageViewEvent>);
        break;
    }
  }

  private initCtx() {
    // TODO: add client id
    return Spider.createContext({});
  }

  static getIntegrationsFactories(configManager: IConfigManager): IntegrationFactory[] {
    const names: IntegrationFactory[] = [];
    // Yandex Metrika
    if (configManager.get('YANDEX_METRIKA_COUNTER_ID')) {
      names.push(yandexMetrikaFactory);
    }

    return names;
  }
}
