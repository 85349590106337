import { EntityId, EntityState, PayloadAction, Update, createSlice } from '@reduxjs/toolkit';
import { DialogMemberViewModel } from '@common/model/dialog/dialogMemberViewModel';
import { DialogViewModel } from '@common/model/dialog/dialogViewModel';
import { DialogMessageViewModel } from '@common/model/dialog/message/dialogMessageViewModel';
import { DialogMessagesResponse } from '@common/model/dialog/dialogMessagesRequest';
import { DialogMemberOnlineEvent } from '@common/model/dialog/events/dialogMemberOnline.event';
import { DialogMemberOfflineEvent } from '@common/model/dialog/events/dialogMemberOffline.event';
import { DialogMemberJoinedEvent } from '@common/model/dialog/events/dialogMemberJoined.event';
import { DialogMemberLeavedEvent } from '@common/model/dialog/events/dialogMemberLeaved.event';
import { DialogMessageReadEvent } from '@common/model/dialog/events/dialogMessageRead.event';
import { DialogMessageDeliveredEvent } from '@common/model/dialog/events/dialogMessageDelivered.event';
import { getAllDialogMembersForUser, getMembersByEntity, insertUniqueIds } from '../../lib';
import { CurrentUser } from '../../../currentUser';
import { messagesAdapter } from './messagesAdapter';
import { DialogViewModelNormalized, dialogsAdapter, normalizeDialog } from './dialogsAdapter';
import { membersAdapter } from './membersAdapter';
import { CurrentDialogState, calculateBlocks, currentDialogInitialState } from './currentDialog';

export interface DialogState {
  dialogs: EntityState<DialogViewModelNormalized>;
  members: EntityState<DialogMemberViewModel>;
  current: CurrentDialogState;
  unreadMessagesCount: number;
  dialogsPage: number;
  totalDialogs: number;
  dialogsPerPage: number;
}

const initialState: DialogState = {
  dialogs: dialogsAdapter.getInitialState(),
  members: membersAdapter.getInitialState(),
  current: currentDialogInitialState,
  unreadMessagesCount: 0,
  dialogsPage: 1,
  totalDialogs: 0,
  dialogsPerPage: 10,
};

const reducerPath = 'dialog';
export type DialogReducerStateShape = { [reducerPath]: DialogState };

const { selectById: selectDialogById } = dialogsAdapter.getSelectors();
const { selectById: selectMemberById } = membersAdapter.getSelectors();
const { selectById: selectMessageById } = messagesAdapter.getSelectors();
const { selectAll: selectAllMembers } = membersAdapter.getSelectors();

export const dialogSlice = createSlice({
  name: reducerPath,
  initialState,
  reducers: {
    messageAdded(state, action: PayloadAction<{ dialogId: number; message: DialogMessageViewModel }>) {
      const { message, dialogId } = action.payload;
      const isActiveDialog = dialogId === state.current.id;
      const openDeliveries = message.deliveries.filter((delivery) => !delivery.isRead);
      const membersUpdates: Update<DialogMemberViewModel>[] = [{ id: message.author.id, changes: message.author }];
      let recipientMember: DialogMemberViewModel | undefined;
      let unreadMessagesCount = 0;

      for (const delivery of openDeliveries) {
        recipientMember = selectMemberById(state.members, delivery.recipientId);
        if (!recipientMember) {
          continue;
        }
        unreadMessagesCount = recipientMember.unreadMessagesCount || 0;
        membersUpdates.push({
          id: delivery.recipientId,
          changes: { unreadMessagesCount: unreadMessagesCount + 1 },
        });
      }

      membersAdapter.updateMany(state.members, membersUpdates);

      const dialog = selectDialogById(state.dialogs, dialogId);
      if (dialog) {
        dialogsAdapter.updateOne(state.dialogs, {
          id: dialogId,
          changes: { lastMessage: message, updatedAt: message.createdAt, messages: insertUniqueIds(dialog.messages, message.id) },
        });
      }

      if (isActiveDialog) {
        messagesAdapter.addOne(state.current.messages, message);
        state.current.blocks = calculateBlocks(state.current);
        state.current.totalCount++;
      }
    },

    messagesLoaded(state, action: PayloadAction<DialogMessagesResponse>) {
      const { items: messages, totalCount } = action.payload;
      if (!messages.length) {
        return state;
      }
      const { selectById } = dialogsAdapter.getSelectors();
      const dialogId = messages[0].dialogId;
      const isActiveDialog = dialogId === state.current.id;
      if (!isActiveDialog) {
        return state;
      }
      const ids = messages.map((message) => message.id);
      const dialog = selectById(state.dialogs, dialogId);
      messagesAdapter.upsertMany(state.current.messages, action.payload.items);
      if (dialog) {
        dialogsAdapter.updateOne(state.dialogs, { id: dialogId, changes: { messages: insertUniqueIds(dialog.messages, ids) } });
      }

      state.current.totalCount = totalCount;
      state.current.blocks = calculateBlocks(state.current);
    },

    messageDelivered(state, action: PayloadAction<DialogMessageDeliveredEvent>) {
      const { message } = action.payload.payload;
      const messageInStore = selectMessageById(state.current.messages, message.id);
      if (!messageInStore) {
        return state;
      }
      messagesAdapter.updateOne(state.current.messages, { id: message.id, changes: message });
    },

    messageRead(state, action: PayloadAction<DialogMessageReadEvent>) {
      const { message, member: memberFromEvent } = action.payload.payload;
      const memberFromStore = selectMemberById(state.members, memberFromEvent.id);

      if (
        memberFromStore &&
        memberFromEvent.unreadMessagesCount !== undefined &&
        new Date(memberFromStore.updatedAt) <= new Date(memberFromEvent.updatedAt)
      ) {
        const unreadMessagesCount = memberFromEvent.unreadMessagesCount;
        membersAdapter.updateOne(state.members, { id: memberFromEvent.id, changes: { unreadMessagesCount } });
      }

      const messageInStore = selectMessageById(state.current.messages, message.id);
      if (!messageInStore) {
        return state;
      }
      messagesAdapter.updateOne(state.current.messages, { id: message.id, changes: message });
    },

    dialogCreated(state, action) {
      dialogsAdapter.addOne(state.dialogs, action.payload);
    },

    memberJoined(state, action: PayloadAction<DialogMemberJoinedEvent>) {
      const { dialogId, member } = action.payload.payload;
      const dialog = selectDialogById(state.dialogs, dialogId);
      membersAdapter.upsertOne(state.members, member);

      if (!dialog) {
        return state;
      }
      dialogsAdapter.updateOne(state.dialogs, { id: dialogId, changes: { members: insertUniqueIds(dialog.members, member.id) } });
    },

    memberLeaved(state, action: PayloadAction<DialogMemberLeavedEvent>) {
      const { member } = action.payload.payload;
      membersAdapter.updateOne(state.members, { id: member.id, changes: { isActive: false } });
    },

    memberOnline(state, action: PayloadAction<DialogMemberOnlineEvent>) {
      const members = selectAllMembers(state.members);
      const relatedMembers = getMembersByEntity(members, action.payload.payload);
      membersAdapter.updateMany(
        state.members,
        relatedMembers.map((member) => ({ id: member.id, changes: { isOnline: true } })),
      );
    },

    memberOffline(state, action: PayloadAction<DialogMemberOfflineEvent>) {
      const members = selectAllMembers(state.members);
      const relatedMembers = getMembersByEntity(members, action.payload.payload);
      membersAdapter.updateMany(
        state.members,
        relatedMembers.map((member) => ({ id: member.id, changes: { isOnline: false } })),
      );
    },

    dialogLoaded(state, action: PayloadAction<DialogViewModel>) {
      const dialog = action.payload;
      const normalizedDialog = normalizeDialog(dialog);
      const existingDialog = selectDialogById(state.dialogs, dialog.id);
      membersAdapter.upsertMany(state.members, dialog.members);
      dialogsAdapter.upsertOne(state.dialogs, {
        ...normalizedDialog,
        messages: existingDialog?.messages || normalizedDialog.messages,
      });
    },

    dialogsLoaded(state, action: PayloadAction<{ items: DialogViewModel[]; totalCount: number; page: number }>) {
      const normalizedDialogs = action.payload.items.map(normalizeDialog);
      const members = action.payload.items.reduce((acc, dialog) => {
        return acc.concat(dialog.members);
      }, [] as DialogMemberViewModel[]);

      const currentDialog = state.current.id ? selectDialogById(state.dialogs, state.current.id) : undefined;
      const currentMembers: DialogMemberViewModel[] = [];
      if (currentDialog) {
        let member: DialogMemberViewModel | undefined;
        for (const memberId of currentDialog.members) {
          member = selectMemberById(state.members, memberId);
          if (!member) {
            continue;
          }
          currentMembers.push(member);
        }
      }

      dialogsAdapter.setAll(state.dialogs, normalizedDialogs);
      membersAdapter.setAll(state.members, members);
      if (currentDialog) {
        dialogsAdapter.upsertOne(state.dialogs, currentDialog);
        membersAdapter.upsertMany(state.members, currentMembers);
      }
      state.totalDialogs = action.payload.totalCount;
      state.dialogsPage = action.payload.page;
    },

    switchToId(state, action: PayloadAction<{ dialogId: EntityId; currentUser: CurrentUser }>) {
      const dialog = selectDialogById(state.dialogs, action.payload.dialogId);
      const members = selectAllMembers(state.members);
      const relatedMember = getAllDialogMembersForUser(members, action.payload.currentUser).find((member) =>
        dialog?.members.includes(member.id),
      );
      state.current.id = action.payload.dialogId;
      state.current.memberId = relatedMember ? relatedMember.id : null;
    },

    increaseCurrentDialogMessagesPage(state) {
      state.current.messagesPage++;
    },

    reset(state) {
      state.current = currentDialogInitialState;
    },
  },
});

export const dialogReducerConfig = {
  [reducerPath]: dialogSlice.reducer,
};
