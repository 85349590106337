import React from 'react';
import { SVG_ICON_CACHE_LIBRARY_ENTRY_EXPIRE_TIME_IN_MS } from './const';
import { SvgIconCacheContext } from './context';
import { SvgIconCacheContextModel, SvgIconCacheStore } from './model';

interface SvgIconCacheProviderProps {
  store?: SvgIconCacheStore;
}

export const SvgIconCacheProvider: React.FC<React.PropsWithChildren<SvgIconCacheProviderProps>> = ({ children, store: externalStore }) => {
  const [innerStore, setInnerStore] = React.useState<SvgIconCacheStore>({});
  const subscriptions = React.useRef<
    Record<
      keyof SvgIconCacheStore,
      {
        subscriptions: unknown[];
      }
    >
  >({});

  const currentStore = externalStore ?? innerStore;

  const setStore = React.useCallback(
    (arg: (store: SvgIconCacheStore) => SvgIconCacheStore) => {
      if (externalStore) {
        arg(externalStore);
      } else {
        setInnerStore((store) => ({ ...arg(store) }));
      }
    },
    [externalStore],
  );

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      const storeKeysToDelete: (keyof SvgIconCacheStore)[] = [];

      for (const key of Object.keys(currentStore)) {
        if (!subscriptions.current[key].subscriptions?.length) {
          storeKeysToDelete.push(key);
        }
      }

      if (storeKeysToDelete.length) {
        setStore((store) => {
          for (const key of storeKeysToDelete) {
            delete store[key];
          }
          return store;
        });
      }
    }, SVG_ICON_CACHE_LIBRARY_ENTRY_EXPIRE_TIME_IN_MS);
    return () => {
      clearInterval(intervalId);
    };
  }, [currentStore, setStore]);

  const subscribe = React.useCallback<Required<SvgIconCacheContextModel>['subscribe']>(
    (key, elements, subscriber) => {
      if (subscriber) {
        if (!subscriptions.current[key]) {
          subscriptions.current[key] = { subscriptions: [subscriber] };
        } else {
          if (!subscriptions.current[key].subscriptions.find((item) => item === subscriber)) {
            subscriptions.current[key].subscriptions.push(subscriber);
          }
        }
      }

      setStore((store) => {
        if (!store[key]) {
          store[key] = { elements };
        }
        return store;
      });
    },
    [setStore],
  );

  const unsubscribe = React.useCallback<Required<SvgIconCacheContextModel>['unsubscribe']>((key, subscriber) => {
    if (subscriber) {
      if (subscriptions.current[key]?.subscriptions) {
        subscriptions.current[key].subscriptions = subscriptions.current[key].subscriptions.filter((item) => item !== subscriber);
      }
    }
  }, []);

  return <SvgIconCacheContext.Provider value={{ store: innerStore, subscribe, unsubscribe }}>{children}</SvgIconCacheContext.Provider>;
};
