import { coreApi } from '@cp/shared/api/core';
import { ApiRoutePathParams } from '@common/api/routePathParams';
import { ApiRouteBody } from '@common/api/routeBody';
import { ApiRoute } from '@common/api/route';
import { generateApiUrl } from '@common/api/configure';
import { apiRouteMethodDict } from '@common/api/routeMethod';
import { ClientProfileCatalogViewModel } from '@common/model/client';
import { CurrentClientFavoriteApiTags, currentClientApiTagTypes } from './tags';

export const currentClientFavoriteApi = coreApi
  .enhanceEndpoints({
    addTagTypes: currentClientApiTagTypes,
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getFavoriteClientsList: builder.query<ClientProfileCatalogViewModel[], void>({
        query: () => {
          return {
            url: generateApiUrl(ApiRoute.ClientFavoriteClientsGet),
          };
        },
        providesTags: [CurrentClientFavoriteApiTags.FavoriteClientsList],
      }),

      createFavoriteClient: builder.mutation<void, ApiRouteBody[ApiRoute.ClientFavoriteClientsCreate]>({
        query: (body) => ({
          url: generateApiUrl(ApiRoute.ClientFavoriteClientsCreate),
          method: apiRouteMethodDict[ApiRoute.ClientFavoriteClientsCreate],
          body,
        }),
        invalidatesTags: [CurrentClientFavoriteApiTags.FavoriteClientsList],
      }),

      deleteFavoriteClient: builder.mutation<void, ApiRoutePathParams[ApiRoute.ClientFavoriteClientsDelete]>({
        query: (params) => ({
          url: generateApiUrl(ApiRoute.ClientFavoriteClientsDelete, params),
          method: apiRouteMethodDict[ApiRoute.ClientFavoriteClientsDelete],
        }),
        invalidatesTags: [CurrentClientFavoriteApiTags.FavoriteClientsList],
      }),
    }),
  });
