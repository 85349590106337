import React from 'react';
import { LogoRect, LogoRectProps } from './logo_rect';
import { LogoSquare, LogoSquareProps } from './logo_square';

export interface LogoProps extends LogoRectProps, LogoSquareProps {
  square?: boolean;
}

export const Logo = ({ square, ...props }: LogoProps) => {
  const Component = square ? LogoSquare : LogoRect;
  return <Component {...props} />;
};
