import { EntityId, EntityState } from '@reduxjs/toolkit';
import { DialogMessageViewModel } from '@common/model/dialog/message/dialogMessageViewModel';
import { DialogMessageBlockPlain, DialogMessageDateBlock } from '../dialogMessageDateBlock';
import { addMessagesToBlocks } from '../addMessagesToBlocks';
import { messagesAdapter } from './messagesAdapter';

const { selectAll: allMessagesSelector, selectEntities } = messagesAdapter.getSelectors();

export interface CurrentDialogState {
  id: EntityId | null;
  memberId: EntityId | null;
  totalCount: number;
  messagesPage: number;
  messages: EntityState<DialogMessageViewModel>;
  blocks: DialogMessageBlockPlain[];
}

export const currentDialogInitialState: CurrentDialogState = {
  totalCount: 0,
  id: null,
  memberId: null,
  messagesPage: 1,
  messages: messagesAdapter.getInitialState(),
  blocks: [],
};

export const calculateBlocks = (state: CurrentDialogState) => {
  const currentDialogMessages = allMessagesSelector(state.messages);
  const messageEntities = selectEntities(state.messages);
  const blocks = state.blocks.map((storeBlock) => {
    return DialogMessageDateBlock.fromPlainObject(storeBlock, messageEntities);
  });
  return addMessagesToBlocks(currentDialogMessages, blocks).map(DialogMessageDateBlock.toPlainObject);
};
